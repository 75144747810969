<template>
  <div>
    <ejs-dialog
      :animationSettings="{ effect: 'None' }"
      ref="createBizDetailPopup"
      :header="`사업장정보 상세`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="832"
      :isModal="true"
      :close="onBizDetailPopupClosed"
      :cssClass="
        isCreate
          ? $t('className.popup.productCodePopupAdd')
          : $t('className.popup.productCodePopupModify')
      "
    >
      <div class="window workPlaceInfoDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">사업장정보 상세</div>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">사업장코드</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailBizCode"
                                        :readonly="!isCreate"
                                        v-model="bizData.bizCode"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">골프장번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailCcNo"
                                        v-model="bizData.ccNo"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">사업장명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailBizName"
                                        v-model="bizData.bizName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">골프장명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailCcName"
                                        v-model="bizData.ccName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">대표자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailRprsntvName"
                                        v-model="bizData.rprsntvName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">지배인명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailManagerName"
                                        v-model="bizData.managerName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">사업자번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-maskedtextbox
                                        ref="detailBizNo"
                                        mask="000-00-00000"
                                        v-model="bizData.bizNo"
                                        class="e-input"
                                        type="text"
                                        v-on:keydown.native="
                                          preventEnterEvent
                                        "
                                      ></ejs-maskedtextbox>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">법인번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-maskedtextbox
                                        ref="detailCprNo"
                                        mask="000000-0000000"
                                        v-model="bizData.cprNo"
                                        class="e-input"
                                        type="text"
                                        v-on:keydown.native="
                                          preventEnterEvent
                                        "
                                      ></ejs-maskedtextbox>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">업태</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailBizCnd"
                                        v-model="bizData.bizCnd"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">업종</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailInduty"
                                        v-model="bizData.induty"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field address">
                              <div class="title required">주소</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group post"
                                    v-if="postRerender"
                                  >
                                    <div class="form">
                                      <input-text
                                        ref="detailPostNo"
                                        v-model="bizData.postNo"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          @click.native="onPostCodeClick()"
                                        >
                                          우편번호
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul class="row">
                                  <li
                                    class="item form-group input"
                                    v-if="postRerender"
                                  >
                                    <div class="form">
                                      <input-text
                                        ref="detailAddr1"
                                        v-model="bizData.addr1"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="detailAddr2"
                                        v-model="bizData.addr2"
                                        class="e-input"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">전화번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        ref="detailTelNo"
                                        v-model="bizData.telNo"
                                        @keydown.native="
                                          preventEnterEvent
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">팩스번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        ref="detailFaxNo"
                                        v-model="bizData.faxNo"
                                        @keydown.native="
                                          preventEnterEvent
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">회원제구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        v-model="bizData.mssmDiv"
                                        :dataSource="mssmDivCodes"
                                        :allowFiltering="false"
                                        :fields="mssmDivCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">정렬번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        id="detailSortNo"
                                        name="detailSortNo"
                                        v-model="bizData.sortNo"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">사용구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="selfFlagTrue"
                                            v-model="bizData.useFlag"
                                            :value="true"
                                          />
                                          <i></i>
                                          <div class="label">예</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            id="selfFlagFalse"
                                            v-model="bizData.useFlag"
                                            :value="false"
                                          />
                                          <i></i>
                                          <div class="label">아니오</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  v-on:click.native="onSaveBizDetail($event)"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onBizDetailPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <daum-postcode-popup
      v-if="isDaumPostcodePopupOpen"
      ref="daumPostcodePopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClose="daumPostcodePopupClosed"
      @popupConfirmed="daumPostcodePopupConfirmed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import { validateFormRefs, ValidType } from "@/utils/formUtil";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "bizDetailPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputText,
    DaumPostcodePopup,
    ErpButton,
  },
  data() {
    return {
      bizData: {},
      isCreate: true,
      postRerender: true,
      isDaumPostcodePopupOpen: false,
      mssmDivCodes: commonCodesGetCommonCode("MSSM_DIV"),
      mssmDivCodesOptions: { text: "comName", value: "comCode" },
      bizDetailInfoForm: {},
      rules: {
        detailBizCode: {
          required: true,
        },
        detailCcNo: {
          required: true,
        },
        detailBizName: {
          required: true,
        },
        detailCcName: {
          required: true,
        },
        detailRprsntvName: {
          required: true,
        },
        detailBizNo: {
          required: true,
          type: ValidType.BIZ,
        },
        detailCprNo: {
          required: true,
          type: ValidType.CPR,
        },
        detailBizCnd: {
          required: true,
        },
        detailInduty: {
          required: true,
        },
        detailPostNo: {
          required: true,
        },
        detailAddr1: {
          required: true,
        },
        detailAddr2: {
          required: true,
        },
        detailTelNo: {
          required: true,
        },
        detailFaxNo: {
          required: true,
        },
      },
    };
  },
  methods: {
    validateFormRefs,
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    onShowBizDetail(bizData, isCreate) {
      this.bizData = bizData;
      this.isCreate = isCreate;
      if (isCreate) {
        bizData.useFlag = true;
        bizData.mssmDiv = commonCodeGetComCodeDefaultValue("MSSM_DIV");
      }
      this.$refs.createBizDetailPopup.show();
    },
    onSaveBizDetail() {
      if (!this.validateFormRefs(this.rules)) {
        return;
      }

      if (this.isCreate) {
        this.$emit("create", this.bizData);
      } else {
        this.$emit("update", this.bizData);
      }
    },
    onBizDetailPopupClosed() {
      this.$refs.createBizDetailPopup.hide();
      this.$emit("popupClosed");
    },
    onPostCodeClick() {
      this.onDaumPostcodePopupOpen();
    },
    onDaumPostcodePopupOpen() {
      this.isDaumPostcodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    daumPostcodePopupClosed: function () {
      this.isDaumPostcodePopupOpen = false;
      this.postRerender = false;
      this.$nextTick(() => {
        this.postRerender = true;
      });
    },
    daumPostcodePopupConfirmed: function (data) {
      this.bizData.postNo = data.zonecode;
      this.bizData.addr1 = data.address;
      this.daumPostcodePopupClosed();
    },
  },
};
</script>
