<template>
  <div>
    <ejs-dialog
      ref="createBsnDetailPopup"
      :header="`영업장 상세`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="832"
      :isModal="true"
      :close="onBsnDetailPopupClosed"
      :cssClass="
        isCreate
          ? $t('className.popup.productCodePopupAdd')
          : $t('className.popup.productCodePopupModify')
      "
    >
      <div class="window businessPlaceInfoDetail">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">영업장정보 상세</div>
                    </div>
                    <div class="header-right" v-if="isCreate">
                      <ul class="header-check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              v-model="isSameBiz"
                              :checked="isSameBiz"
                              @change="onCheckBoxHandleChange"
                            />
                            <i></i>
                            <div class="label">사업장 정보와 동일</div>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <div class="title required">영업장구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="detailBsnDiv"
                                        v-model="bsnData.bsnDiv"
                                        :dataSource="bsnDivCodes"
                                        :allowFiltering="false"
                                        :fields="bsnDivCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">영업장명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailBsnName"
                                        v-model="bsnData.bsnName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">사업장명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailBizName"
                                        v-model="bsnData.bizName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">대표자명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailRprsntvName"
                                        v-model="bsnData.rprsntvName"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">사업자번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-maskedtextbox
                                        ref="detailBizNo"
                                        mask="000-00-00000"
                                        v-model="bsnData.bizNo"
                                        class="e-input"
                                        type="text"
                                        v-on:keydown.native="
                                          preventEnterEvent
                                        "
                                      ></ejs-maskedtextbox>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field required">
                              <div class="title required">법인번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-maskedtextbox
                                        ref="detailCprNo"
                                        mask="000000-0000000"
                                        v-model="bsnData.cprNo"
                                        class="e-input"
                                        type="text"
                                        v-on:keydown.native="
                                          preventEnterEvent
                                        "
                                      ></ejs-maskedtextbox>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">업태</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailBizcnd"
                                        v-model="bsnData.bizcnd"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">업종</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailInduty"
                                        v-model="bsnData.induty"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field address">
                              <div class="title required">주소</div>
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group post"
                                    v-if="postRerender"
                                  >
                                    <div class="form">
                                      <input-text
                                        ref="detailPostNo"
                                        v-model="bsnData.postNo"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group button">
                                    <ul class="button">
                                      <li>
                                        <erp-button
                                          button-div="GET"
                                          @click.native="onPostCodeClick()"
                                        >
                                          우편번호
                                        </erp-button>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                                <ul class="row">
                                  <li
                                    class="item form-group input"
                                    v-if="postRerender"
                                  >
                                    <div class="form">
                                      <input-text
                                        ref="detailBsnAddr1"
                                        v-model="bsnData.addr1"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group input">
                                    <div class="form">
                                      <input-text
                                        ref="addr2"
                                        v-model="bsnData.addr2"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">전화번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        ref="detailTelNo"
                                        v-model="bsnData.telNo"
                                        @keydown.native="
                                          preventEnterEvent
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">팩스번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <component-telephone
                                        ref="detailFaxNo"
                                        v-model="bsnData.faxNo"
                                        @keydown.native="
                                          preventEnterEvent
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">특정시설물코드</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="spcCode"
                                          v-model="bsnData.spcCode"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">특정시설물명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="spcName"
                                          v-model="bsnData.spcName"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">세무서코드</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailTaxofcCode"
                                        v-model="bsnData.taxofcCode"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">홈텍스ID</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailHometaxId"
                                        v-model="bsnData.hometaxId"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">VAN종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="detailVanKind"
                                        v-model="bsnData.vanKind"
                                        :dataSource="vanKindCodes"
                                        :allowFiltering="false"
                                        :fields="vanKindCodesOptions"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">VAN아이피</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="vanIp"
                                        v-model="bsnData.vanIp"
                                      />
                                    </div>
                                    <div class="validation">
                                      <div class="e-tip-content"></div>
                                      <div class="e-tip-top"></div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title required">단말ID</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="detailTermId"
                                        v-model="bsnData.termId"
                                        @keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">VAN포트</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="port"
                                        v-model="bsnData.port"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">정렬번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="sortNo"
                                        v-model="bsnData.sortNo"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">PG종류</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="pgKind"
                                        v-model="bsnData.pgKind"
                                        :dataSource="pgKindCodes"
                                        :allowFiltering="false"
                                        :fields="pgKindCodesOptions"
                                        cssClass="body-data-dropdown"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">PG상점아이디</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        ref="pgStoreId"
                                        v-model="bsnData.pgStoreId"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">POS 영업장코드</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="posBsnCode"
                                          v-model="bsnData.posBsnCode"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <div class="title">POS 시리얼번호</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                          ref="posSerialNo"
                                          v-model="bsnData.posSerialNo"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveBsnDetail"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onBsnDetailPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <daum-postcode-popup
      v-if="isDaumPostcodePopupOpen"
      ref="daumPostcodePopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClose="daumPostcodePopupClosed"
      @popupConfirmed="daumPostcodePopupConfirmed"
    />
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import DaumPostcodePopup from "@/views/common/DaumPostcodePopup.vue";
import { validateFormRefs, ValidType } from "@/utils/formUtil";
import InputText from "@/components/common/text/InputText";
import ComponentTelephone from "@/components/ComponentTelephone";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "bsnDetailPopup",
  mixins: [commonMixin],
  components: {
    ComponentTelephone,
    InputText,
    DaumPostcodePopup,
    ErpButton,
  },
  data() {
    return {
      postRerender: true,
      isSameBiz: false,
      bsnData: {
        vanKind: commonCodeGetComCodeDefaultValue("VAN_KIND"),
        bsnDiv: commonCodeGetComCodeDefaultValue("BSN_DIV"),
        cprNo: null,
      },
      bizData: {},
      isCreate: true,
      isDaumPostcodePopupOpen: false,
      bsnDivCodes: commonCodesGetCommonCode("BSN_DIV"),
      bsnDivCodesOptions: { text: "comName", value: "comCode" },
      vanKindCodes: commonCodesGetCommonCode("VAN_KIND"),
      vanKindCodesOptions: { text: "comName", value: "comCode" },
      pgKindCodes: commonCodesGetCommonCode("PG_KIND"),
      pgKindCodesOptions: { text: "comName", value: "comCode" },
      bsnDetailInfoForm: {},
      rules: {
        /* detailBsnId: {
          required: true
        },*/
        detailBsnDiv: {
          required: true,
        },
        detailBsnName: {
          required: true,
        },
        detailBizName: {
          required: true,
        },
        detailRprsntvName: {
          required: true,
        },
        detailBizNo: {
          required: true,
          type: ValidType.BIZ,
        },
        detailCprNo: {
          required: true,
          type: ValidType.CPR,
        },
        detailBizcnd: {
          required: true,
        },
        detailInduty: {
          required: true,
        },
        detailPostNo: {
          required: true,
        },
        detailBsnAddr1: {
          required: true,
        },
        detailTelNo: {
          required: true,
        },
        detailFaxNo: {
          required: true,
        },
        detailTaxofcCode: {
          required: true,
        },
        detailHometaxId: {
          required: true,
        },
        detailVanKind: {
          required: true,
        },
        detailTermId: {
          required: true,
        },
      },
    };
  },
  async mounted() {
    await this.$nextTick();
    this.pgKindCodes.unshift({ text: "ALL", value: "" });
  },
  methods: {
    validateFormRefs,
    onCheckBoxHandleChange(e) {
      if (e.target.checked) {
        if (this.bizData) {
          Object.keys(this.bizData).map((key) => {
            this.bsnData[key] = this.bizData[key];
          });
        }
      }
    },
    onShowBsnDetail(bsnData, isCreate, bizData) {
      this.isCreate = isCreate;
      this.isSameBiz = false;
      if (isCreate) {
        this.bizData = bizData;
      } else {
        this.bsnData = bsnData;
      }
      this.$refs.createBsnDetailPopup.show();
    },
    onSaveBsnDetail() {
      if (!this.validateFormRefs(this.rules)) {
        return;
      }
      if (this.isCreate) {
        this.$emit("create", this.bsnData);
      } else {
        this.$emit("update", this.bsnData);
      }
    },
    onBsnDetailPopupClosed() {
      this.$refs.createBsnDetailPopup.hide();
      this.$emit("popupClosed");
    },
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    onPostCodeClick() {
      this.onDaumPostcodePopupOpen();
    },
    onDaumPostcodePopupOpen() {
      this.isDaumPostcodePopupOpen = true;
      this.$nextTick(() => {
        this.$refs.daumPostcodePopup.showPopup();
      });
    },
    daumPostcodePopupClosed: function () {
      this.isDaumPostcodePopupOpen = false;
      this.postRerender = false;
      this.$nextTick(() => {
        this.postRerender = true;
      });
    },
    daumPostcodePopupConfirmed: function (data) {
      this.bsnData.postNo = data.zonecode;
      this.bsnData.addr1 = data.address;
      this.daumPostcodePopupClosed();
    },
  },
};
</script>
