<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">조건검색</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                  ref="searchConditionsPartDiv"
                  v-model="searchConditions.searchDiv"
                  :dataSource="searchConditionOptions.searchDivOptions"
                  :allowFiltering="false"
                  :fields="searchConditionOptions.fields"
                  cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
              <li class="item">
                <input-text
                  ref="searchMemberName"
                  v-model="searchConditions.searchWord"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">사업장 등록</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      @click.native="addBiz"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :disabled="!this.selectedDatas.selectedBizMst"
                      @click.native="deleteBiz"
                  >
                    삭제
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="bizMstGrid"
                :allowResizing="true"
                :dataSource="tsBizMstList"
                @rowSelected="bizMstGridSelected"
                @recordClick="bizMstClick"
                @queryCellInfo="bizMstGridQueryCellInfo"
                :isNOColumnDisplay="false"
                :columns="bizMstColumn"
                :provides="gridProvide"
                :editSettings="noneEditSettings"
                :selectionSettings="singleSelectionOptions"
              ></ejs-grid-wrapper>
            </div>
          </div>
        </section>
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-02">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">영업장 등록</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :disabled="!this.selectedDatas.selectedBizMst"
                      @click.native="addBsn"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :disabled="!this.selectedDatas.selectedBsn"
                      @click.native="deleteBsn"
                  >
                    삭제
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="bsnGrid"
                :allowResizing="true"
                :dataSource="tsBsnMstList"
                @rowSelected="bsnGridSelected"
                @recordClick="bsnClick"
                @queryCellInfo="bsnGridGridQueryCellInfo"
                :isNOColumnDisplay="false"
                :columns="bsnColumn"
                :provides="gridProvide"
                :editSettings="noneEditSettings"
                :selectionSettings="singleSelectionOptions"
              ></ejs-grid-wrapper>
            </div>
          </div>
        </section>
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-03">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">영업장 매장 등록</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :is-icon-custom="true"
                      :is-shortcut-button="true"
                      :ignore="isPopupOpened"
                      :disabled="!this.selectedDatas.selectedBsn"
                      @click.native="saveStoreBsn"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :disabled="
                      !this.selectedDatas.selectedBsn"
                      :is-icon-custom="true"
                      @click.native="addStoreBsn"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      @click.native="deleteBsnStore"
                  >
                    삭제
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="storeBsnGrid"
                :allowResizing="true"
                :dataSource="tsStoreBsnInfoList"
                :columns="bsnStoreColumn"
                :provides="gridProvide"
                :editSettings="editSettings"
                :selectionSettings="selectionOptions"
                @headerCellInfo="storeBsnGridHeaderCellInfo"
                @queryCellInfo="storeBsnGridCustomiseCell"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <biz-detail-popup
      v-if="isBizDetailPopupOpen"
      ref="bizDetailPopup"
      @popupClosed="onBizDetailPopupClosed"
      @create="onCreateBizDetail"
      @update="onUpdateBizDetail"
    ></biz-detail-popup>
    <bsn-detail-popup
      v-if="isBsnDetailPopupOpen"
      ref="bsnDetailPopup"
      @popupClosed="onBsnDetailPopupClosed"
      @create="onCreateBsnDetail"
      @update="onUpdateBsnDetail"
    ></bsn-detail-popup>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field .content .item {width: 120px;}
body .appContent .body-article .article-section {height: calc(100% / 3);}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import { sortBy as _sortBy } from "lodash";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  deleteBizInfo,
  deleteBsnInfo,
  getBizInfo,
  getBsnInfo,
  getStoreBsnInfo,
  postBizInfo,
  postBsnInfo,
  putBizInfo,
  putBsnInfo,
  putStoreBsnInfo,
} from "@/api/bizCodeManagement";
import { Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import bizDetailPopup from "@/views/code-management/popup/BizDetailPopup";
import bsnDetailPopup from "@/views/code-management/popup/BsnDetailPopup";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { companyRegistrationNumberAccess } from "@/utils/gridUtil";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import messagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {getBizMstInfo} from "@/api/common";
import {commonCodesSetBizMstInfo} from "@/utils/commonCodes";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "WorkplaceRegistration",
  mixins: [routeViewMixin, commonMixin, messagePopupDialogMixin],
  components: {
    InputText,
    bizDetailPopup,
    bsnDetailPopup,
    ejsGridWrapper,
    ErpButton,
  },
  async beforeMount() {
    if (
      this.searchConditionOptions.searchDivOptions &&
      this.searchConditionOptions.searchDivOptions.length > 0
    ) {
      this.searchConditions.searchDiv = this.searchConditionOptions.searchDivOptions[0].comCode;
    }
    await this.fetchBizInfo();
  },
  data() {
    return {
      isBizDetailPopupOpen: false,
      isBsnDetailPopupOpen: false,
      tsBizMstList: [],
      tsBsnMstList: [],
      tsStoreBsnInfoList: [],
      gridProvide: [Edit, ForeignKey, Resize],
      bizMstColumn: [
        {
          field: "bizCode",
          headerText: "사업장코드",
          isPrimaryKey: true,
          type: "string",
        },
        { field: "bizName", headerText: "사업장명", type: "string" },
        { field: "ccName", headerText: "골프장명", type: "string" },
        { field: "rprsntvName", headerText: "대표자명", type: "string" },
        {
          field: "bizNo",
          headerText: "사업자번호",
          valueAccessor: companyRegistrationNumberAccess,
          type: "string",
        },
      ],
      bsnColumn: [
        {
          field: "bsnDiv",
          headerText: "영업장구분",
          type: "string",
          allowEditing: false,
          editType: "dropdownedit",
          groupCode: "BSN_DIV",
          isCommonCodeField: true,
        },
        { field: "bsnName", headerText: "영업장명", type: "string" },
        { field: "bizName", headerText: "사업장명", type: "string" },
        { field: "vanKind", headerText: "VAN 종류", type: "string" },
        { field: "termId", headerText: "단말번호", type: "string" },
        { field: "spcCode", headerText: "특정시설물코드", type: "string" },
        { field: "spcName", headerText: "특정시설물명", type: "string" },
      ],
      bsnStoreColumn: [
        {
          allowEditing: false,
          field: "_rid",
          headerText: "NO",
          textAlign: "Center",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "storeCode",
          headerText: "매장코드",
          allowEditing: true,
          editType: "dropdownedit",
          groupCode: "STORE_CODE",
          isCommonCodeField: true,
          type: "string",
        },
        {
          field: "invntryFlag",
          headerText: "재고여부",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "wrhousFlag",
          headerText: "창고여부",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "posFlag",
          headerText: "POS여부",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "etcSalesFlag",
          headerText: "기타매출",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "galleyCreatFlag",
          headerText: "갤러리생성",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "tableUseFlag",
          headerText: "테이블사용",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "prpayFrpyDiv",
          headerText: "선후불구분",
          allowEditing: true,
          editType: "dropdownedit",
          groupCode: "PRPAY_FRPY_DIV",
          isCommonCodeField: true,
          type: "string",
        },
        {
          field: "salesFeeRate",
          headerText: "매출수수료율",
          isNumericType: true,
          inputNumberProperty: { allowDot: true, min: 0, max: 100 },
          type: "string",
          allowEditing: true,
          textAlign: "right",
        },
        {
          field: "cardFeeRate",
          headerText: "카드수수료율",
          isNumericType: true,
          inputNumberProperty: { allowDot: true, min: 0, max: 100 },
          type: "string",
          allowEditing: true,
          textAlign: "right",
        },
        {
          field: "gcDiv",
          headerText: "골프/객실구분",
          allowEditing: true,
          editType: "dropdownedit",
          groupCode: "GC_DIV",
          isCommonCodeField: true,
          type: "string",
        },
        {
          field: "useFlag",
          headerText: "사용여부",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "classCode1",
          headerText: "분류1",
          allowEditing: true,
          type: "string",
        },
        {
          field: "classCode2",
          headerText: "분류2",
          allowEditing: true,
          type: "string",
        },
        {
          field: "sortNo",
          headerText: "정렬순서",
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: { allowDot: true, min: 0, max: 100 },
          type: "string",
          textAlign: "right",
        },
      ],
      selectionOptions: { type: "Multiple" },
      singleSelectionOptions: { type: "Single", enableToggle: false },
      searchConditionOptions: {
        searchDivOptions: [
          {
            comName: "사업장명",
            comCode: "bizName",
          },
          {
            comName: "골프장명",
            comCode: "ccName",
          },
          {
            comName: "사업장코드",
            comCode: "bizCode",
          },
        ],
        fields: { text: "comName", value: "comCode" },
      },
      searchConditions: {
        searchDiv: null,
        searchWord: "",
      },
      selectedDatas: {
        selectedBizMst: null,
        selectedBsn: null,
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      noneEditSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
      },
    };
  },
  computed: {
    isPopupOpened() {
      return this.isBizDetailPopupOpen || this.isBsnDetailPopupOpen;
    },
  },
  methods: {
    addBiz() {
      this.isBizDetailPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.bizDetailPopup.onShowBizDetail({}, true);
      });
    },
    addBsn() {
      this.isBsnDetailPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.bsnDetailPopup.onShowBsnDetail(
          {},
          true,
          this.selectedDatas.selectedBizMst
        );
      });
    },
    addStoreBsn() {
      this.$refs.storeBsnGrid.addRecord({
        storeCode: "SHOP",
        invntryFlag: false,
        wrhousFlag: false,
        posFlag: false,
        etcSalesFlag: false,
        galleyCreatFlag: false,
        tableUseFlag: false,
        gcDiv: "G",
        prpayFrpyDiv: "0",
        salesFeeRate: 0,
        classCode1: "",
        classCode2: "",
        useFlag: true,
        cardFeeRate: 0,
      });
    },
    async saveStoreBsn() {
      const changedObj = this.$refs.storeBsnGrid.getBatchChanges();
      const tmpBody = {
        bizMstCode: this.selectedDatas.selectedBizMst.bizCode,
        bsnId: this.selectedDatas.selectedBsn.bsnId,
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords;
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putStoreBsnInfo(tmpBody);
        this.tsStoreBsnInfoList = [];
        await this.fetchStoreBsnInfo();
      }
    },
    async deleteBiz() {
      if (await this.confirm("삭제하시겠습니까?")) {
        if (this.selectedDatas.selectedBizMst) {
          await deleteBizInfo(
              this.selectedDatas.selectedBizMst.bizCode,
              true
          );
          this.selectedDatas.selectedBizMst = null;
          this.selectedDatas.selectedBsn = null;
          await this.fetchBizInfo();
        }
      }
    },
    async deleteBsn() {
      if (await this.confirm("삭제하시겠습니까?")) {
        if (
          this.selectedDatas.selectedBizMst &&
          this.selectedDatas.selectedBsn
        ) {
          await deleteBsnInfo(
              this.selectedDatas.selectedBizMst.bizCode,
              this.selectedDatas.selectedBsn.bsnId,
              true
          );
          this.selectedDatas.selectedBsn = null;
          this.tsBsnMstList = [];
          this.tsStoreBsnInfoList = [];
          await this.fetchBsnInfo();
        }
      }
    },
    async deleteBsnStore() {
      this.$refs.storeBsnGrid.deleteRecord();
    },
    async fetchBizInfo() {
      const {
        value: { tsBizMstList },
      } = await getBizInfo(
          this.searchConditions.searchDiv
              ? this.searchConditions.searchDiv || undefined
              : undefined,
          this.searchConditions.searchWord || undefined
      );
      this.tsBizMstList = _sortBy(tsBizMstList || [], "sortNo");
      this.selectedDatas.selectedBsn = null;
      this.tsBsnMstList = [];
      this.tsStoreBsnInfoList = [];
    },
    async onViewButtonClicked() {
      await this.fetchBizInfo();
    },
    async bizMstGridSelected(args) {
      if (
        this.selectedDatas.selectedBizMst &&
        this.selectedDatas.selectedBizMst.bizCode !== args.data.bizCode
      ) {
        this.tsBsnMstList = [];
        this.tsStoreBsnInfoList = [];
      }
      this.selectedDatas.selectedBsn = null;
      this.selectedDatas.selectedBizMst = args.data;

      if (args.target.value !== "bizPopup") {
        await this.fetchBsnInfo();
      }
    },
    async bsnGridSelected(args) {
      this.selectedDatas.selectedBsn = args.data;
      this.tsStoreBsnInfoList = [];
      await this.fetchStoreBsnInfo();
    },
    async fetchBsnInfo() {
      const {
        value: { tsBsnMstList },
      } = await getBsnInfo(
          this.selectedDatas.selectedBizMst.bizCode || undefined
      );
      this.tsBsnMstList = tsBsnMstList;
    },
    async fetchStoreBsnInfo() {
      const {
        value: { storeBsnInfoList },
      } = await getStoreBsnInfo(
          this.selectedDatas.selectedBizMst.bizCode || undefined,
          this.selectedDatas.selectedBsn.bsnId || undefined
      );
      this.tsStoreBsnInfoList = _sortBy(storeBsnInfoList.map((element, index) => {
        return {
          _rid: index + 1,
          classCode1: element.classCode1 ? element.classCode1 : "",
          classCode2: element.classCode2 ? element.classCode2 : "",
          ...element,
        };
      }), "sortNo");
    },
    onBizDetailPopupClosed() {
      this.isBizDetailPopupOpen = false;
    },
    onBsnDetailPopupClosed() {
      this.isBsnDetailPopupOpen = false;
    },
    async onCreateBizDetail(data) {
      this.searchConditions.searchWord = null;
      try {
        await postBizInfo({bizCodeInfo: data});
        this.infoToast(this.$t("main.popupMessage.saved"));
        this.isBizDetailPopupOpen = false;
        await this.fetchBizInfo();
      } catch (e) {
        this.errorToast("에러가 발생하였습니다.<br>" + e.message);
      }
    },
    async onUpdateBizDetail(data) {
      this.searchConditions.searchWord = null;
      try {
        await putBizInfo({bizCodeInfo: data});

        this.infoToast(this.$t("main.popupMessage.saved"));
        this.isBizDetailPopupOpen = false;
        await this.fetchBizInfo();
        if (data.bizCode === getCurrentBizCode()) {
          const {
            data: { bisMstInfo },
          } = await getBizMstInfo();

          // 해당 유저의 사업장 정보 저장
          commonCodesSetBizMstInfo(bisMstInfo);
        }
      } catch (e) {
        this.errorToast("에러가 발생하였습니다.<br>" + e.message);
      }
    },
    async onCreateBsnDetail(data) {
      try {
        let tmpData = {
          bizMstCode: this.selectedDatas.selectedBizMst.bizCode,
          bsnCodeInfo: data,
        };
        await postBsnInfo(tmpData);
        this.infoToast(this.$t("main.popupMessage.saved"));
        this.isBsnDetailPopupOpen = false;
        this.selectedDatas.selectedBsn = null;
        this.tsBsnMstList = [];
        this.tsStoreBsnInfoList = [];
        await this.fetchBsnInfo();
      } catch (e) {
        this.errorToast("에러가 발생하였습니다.<br>" + e.message);
      }
    },
    async onUpdateBsnDetail(data) {
      try {
        let tmpData = {
          bizMstCode: this.selectedDatas.selectedBizMst.bizCode,
          bsnCodeInfo: data,
        };
        await putBsnInfo(tmpData);
        this.infoToast(this.$t("main.popupMessage.saved"));
        this.isBsnDetailPopupOpen = false;
        this.tsBsnMstList = [];
        this.tsStoreBsnInfoList = [];
        await this.fetchBsnInfo();
      } catch (e) {
        this.errorToast("에러가 발생하였습니다.<br>" + e.message);
      }
    },
    bizMstClick(args) {
      if (args.column.field === "bizCode" || args.column.field === "bizName") {
        this.isBizDetailPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.bizDetailPopup.onShowBizDetail(
            JSON.parse(JSON.stringify(args.rowData)),
            false
          );
        });
      }
    },
    bizMstGridQueryCellInfo(args) {
      if (!args.data.useFlag) {
        args.cell.style.color = "red";
        args.cell.style.textDecoration = "line-through";
      }

      if (args.column.field === "bizCode" || args.column.field === "bizName") {
        args.cell.classList.add(this.$t("className.grid.clickArea")); // 클릭영역 셀 처리
      }
    },
    bsnGridGridQueryCellInfo(args) {
      if (args.column.field === "bsnDiv" || args.column.field === "bsnName") {
        args.cell.classList.add(this.$t("className.grid.clickArea")); // 클릭영역 셀 처리
      }
    },
    bsnClick(args) {
      if (args.column.field === "bsnDiv" || args.column.field === "bsnName") {
        this.isBsnDetailPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.bsnDetailPopup.onShowBsnDetail(
              JSON.parse(JSON.stringify(args.rowData)),
              false
          );
        });
      }
    },
    storeBsnGridHeaderCellInfo(args) {
      if (args.cell.column.field === "storeCode") {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    storeBsnGridCustomiseCell(args) {
      if (args.column.allowEditing) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
  },
};
</script>

<style scoped></style>
